const Speciality = (props: any) => {
    const { speciality } = props;

    return (
        <div className="spe-items">
            <div className="icon-div">
                <img src={speciality.icon} alt="" />
            </div>
            <div className="desc-div">
                <h4>{speciality.name}</h4>
                <p className="main-para">{speciality.description}</p>
                <a href="">
                    <div className="main-outline-btn">See Providers</div>
                </a>
            </div>
        </div>
    );
}

export default Speciality;