const Feature = (props: any) => {
    const { feature } = props;
    return (
        <div className="items">
            <div className="img-div">
                <img src={feature.icon} alt="" className="img-fluid" />
            </div>
            <h6 dangerouslySetInnerHTML={{__html: feature.title}}></h6>
            <p>{feature.description}</p>
        </div>
    );
}

export default Feature;