import ratingStarIcon from '../../../assets/img/svg/rating-star.svg';
import availableIcon from '../../../assets/img/svg/available-icn.svg';
import unavailableIcon from '../../../assets/img/svg/unavailable-icn.svg';

const Doctors = (props: any) => {
    const { doctor, detailed } = props;
    
    return (
        <>
            {detailed === true ?
                // search section with description
                <div className="result-doctors">
                    <div className="img-sec">
                        <img src={doctor.image} alt="" className="img-fluid" />
                        {doctor.recommend === true ? <div className="recommend-sec">Highly Recommend</div> : ''}
                    </div>
                    <div className="name-sec">
                        <h4 className="doctor-name">{doctor.name}</h4>
                        <p className="doctor-designation">{doctor.speciality}</p>
                    </div>
                    <div className="rating-sec">
                        <img src={ratingStarIcon} alt="" className="img-fluid" />
                        <p className="rating">{doctor.rating}</p>
                        <p className="review">({doctor.reviews} Reviews)</p>
                    </div>
                    <div className="doc-desc">
                        <p>{doctor.description}</p>
                    </div>
                    <div className="available-sec">
                        <div className={doctor.available === true ? 'available mb-2' : 'unavailable mb-2'}>
                            <img src={doctor.available === true ? availableIcon : unavailableIcon} alt="" className="img-fluid" />
                            <p>Available Today</p>
                        </div>
                    </div>
                    <a href="" className="main-fill-btn">Schedule Appoinment</a>
                </div>
            :
                // Home page content without description
                <div className="doctors item">
                    <div className="card-img">
                        <img src={doctor.image} alt="" className="img-fluid" />
                        {doctor.recommend === true ?
                            <div className="recomment">
                                <p>Highly Recommend</p>
                            </div>
                        : ''}
                    </div>
                    <div className="card-desc">
                        <div className="desc-top">
                            <h6 className="docname">{doctor.name}</h6>
                            <p className="designation">{doctor.speciality}</p>
                        </div>
                        <div className="desc-bottom">
                            <div className={doctor.available === true ? 'available mb-2' : 'unavailable mb-2'}>
                                <img src={doctor.available === true ? availableIcon : unavailableIcon} alt="" className="img-fluid" />
                                <p>Available Today</p>
                            </div>
                            <div className="main-btm">
                                <div className="left d-flex align-item-center">
                                    <img src={ratingStarIcon} alt="" className="img-fluid" />
                                    <p className="rating">{doctor.rating}</p>
                                    <p className="review">({doctor.reviews} Reviews)</p>
                                </div>
                                <div className="right">
                                    <a href="" className="main-fill-btn">Book Appointment</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default Doctors;