import searchDoctors from '../../../assets/img/svg/search-doctors.svg';
import OwlCarousel from "react-owl-carousel";
import searchAd from '../../../assets/img/search-ad.jpg';
import tabletMain from '../../../assets/img/gif/tablet-main.gif';
import mobileMain from '../../../assets/img/gif/mobile-main.gif';
import Doctors from "../Doctors/Doctors";

const SearchResult = (props: any) => {

    const { doctorsSet } = props;
    
    return (
    <div className="searchresult-sec">
        <div className="topsec">
            <h2 className="main-head">
                <img src={searchDoctors} alt="" className="img-fluid" />
                Matching Results
            </h2>
        </div>
        <div className="search-main">
            <div className="row">
                <div className="col-lg-9 col-12 left">
                    <OwlCarousel className='search-results-main owl-theme'
                        margin={20}
                        loop={false}
                        nav={true}
                        dots={false}
                        responsive={{
                            0:{
                            items:1
                            },
                            600:{
                            items:1
                            },
                            1000:{
                            items:1
                            }
                        }}
                    >
                        {doctorsSet.map((row: any, index: any) => (
                            <div className="results-sec" key={index}>
                                {row.map((item: any, index: any) => (
                                    <Doctors doctor={item} detailed={true} key={index}/>
                                ))}
                            </div>
                        ))}
                    </OwlCarousel>
                </div>
                <div className="col-lg-3 col-12 right-ad">
                    <a href="">
                        <img src={searchAd} alt="" className="img-fluid d-lg-block d-md-none d-none" />
                        <img src={tabletMain} alt="" className="img-fluid d-lg-none d-md-block d-none" />
                        <img src={mobileMain} alt="" className="img-fluid d-lg-none d-md-none d-block" />
                    </a>
                </div>
            </div>
        </div>
    </div>
    );
}

export default SearchResult;