import calenderIcon from '../../../assets/img/svg/calender-icn.svg';
import btnArrow from '../../../assets/img/svg/btn-arrow.svg';

const Article = (props: any) => {
    const { article } = props;

    return (
        <div className="blog-items item">
            <div className="blog-img-div">
                <img src={article.image} alt="" className="img-fluid" />
            </div>
            <div className="blog-desc">
                <div className="blog-top">
                    <a href="" className="category-div">
                        <p className="category">{article.category}</p>
                    </a>
                    <div className="date-div">
                        <img src={calenderIcon} alt="" className="img-fluid" />
                        <p className="date">{article.date}</p>
                    </div>
                </div>
                <div className="blog-details">
                    <h4 className="blog-title">{article.title}</h4>
                    <p className="blog-para" dangerouslySetInnerHTML={{__html: article.blog}}></p>
                </div>
                <a href="" className="read-more-btn">
                    <p>Read More</p>
                    <img src={btnArrow} alt="" />
                </a>
            </div>
        </div>
    );
}

export default Article;