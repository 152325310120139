const Testimonial = (props: any) => {
    const { testimonial } = props;

    return (
        <div className="testi-items item">
            <div className="icon-div">
              <svg width="48" height="36" viewBox="0 0 48 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18.0537 0.625H4.83496C2.40521 0.625 0.428711 2.6015 0.428711 5.03125V18.25C0.428711 20.6798 2.40521 22.6562 4.83496 22.6562H10.7637L8.53408 34.1258C8.45017 34.5569 8.56349 35.0022 8.84246 35.3407C9.12143 35.6792 9.53668 35.875 9.97559 35.875H14.734C16.0112 35.875 17.1465 35.0395 17.5331 33.8296L21.8088 23.6875C21.8332 23.6294 21.854 23.5699 21.8704 23.5097C22.262 22.1026 22.46 20.6475 22.46 19.1852V5.03125C22.46 2.6015 20.4835 0.625 18.0537 0.625Z"
                  fill="#04285B" />
                <path
                  d="M43.0225 0.625H29.8037C27.374 0.625 25.3975 2.6015 25.3975 5.03125V18.25C25.3975 20.6798 27.374 22.6562 29.8037 22.6562H35.7332L33.5028 34.1258C33.4182 34.5569 33.5315 35.0022 33.8112 35.3407C34.0895 35.6792 34.5054 35.875 34.9443 35.875H39.7034C40.9814 35.875 42.116 35.0395 42.5018 33.8289L46.7775 23.6875C46.8019 23.6294 46.822 23.5699 46.8392 23.5097C47.2308 22.1012 47.4287 20.646 47.4287 19.1852V5.03125C47.4287 2.6015 45.4522 0.625 43.0225 0.625Z"
                  fill="#04285B" />
              </svg>

            </div>
            <p className="testimonial-content">{testimonial.message}</p>
            <div className="testi-userdiv">
              <img src={testimonial.image} alt="" className="img-fluid" />
              <div className="user-desc">
                <h6 className="testi-username">{testimonial.name}</h6>
                <p className="details">{testimonial.speciality}</p>
              </div>
            </div>
        </div>
    )
}

export default Testimonial;