import bannerLogo from './assets/img/logo/banner-logo.png';
import btnArrow from './assets/img/svg/btn-arrow.svg';
import heroImg1 from './assets/img/hero-section/img-1.png';
import heroImg2 from './assets/img/hero-section/img-2.png';
import heroImg3 from './assets/img/hero-section/img-3.png';
import familyMedicine from './assets/img/svg/family-medicine.svg';
import cardiology from './assets/img/svg/cardiology.svg';
import dermatology from './assets/img/svg/dermatology.svg';
import pediatrics from './assets/img/svg/pediatrics.svg';
import patientsImg from './assets/img/patients.png';
import benefitsIcon from './assets/img/svg/benefits-icon.svg';
import doctorsImg from './assets/img/doctors.png';
import ctaDocImg from './assets/img/cta-doc.png';
import doctorImg1 from './assets/img/doctor-1.jpg';
import doctorImg2 from './assets/img/doctor-2.jpg';
import doctorImg3 from './assets/img/doctor-3.jpg';
import userImg1 from './assets/img/user1.jpg';
import whyChooseIcon1 from './assets/img/svg/why-choose-icons/1.svg';
import whyChooseIcon2 from './assets/img/svg/why-choose-icons/2.svg';
import whyChooseIcon3 from './assets/img/svg/why-choose-icons/3.svg';
import whyChooseIcon4 from './assets/img/svg/why-choose-icons/4.svg';
import whyChooseIcon5 from './assets/img/svg/why-choose-icons/5.svg';
import whyChooseIcon6 from './assets/img/svg/why-choose-icons/6.svg';
import whyChooseIcon7 from './assets/img/svg/why-choose-icons/7.svg';
import whyChooseIcon8 from './assets/img/svg/why-choose-icons/8.svg';
import updatesImg from './assets/img/updates-img.png';
import blog1 from './assets/img/blogs/1.jpg';
import blog2 from './assets/img/blogs/2.jpg';
import blog3 from './assets/img/blogs/3.jpg';
import footerCtaImg from './assets/img/footer-cta-img.png';
import appleStoreIcon from './assets/img/svg/apple-store.svg';
import googlePlayIcon from './assets/img/svg/google-play.svg';
import footerLogo from './assets/img/logo/mycarepedia-footer-logo.png';
import dividerSvg from './assets/img/svg/divider.svg';
import footerDivideMobileSvg from './assets/img/svg/footer-divide-mobile.svg';

import './App.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from './common/components/Header/Header';
import Doctors from './common/components/Doctors/Doctors';
import Speciality from './common/components/Speciality/Speciality';
import Testimonial from './common/components/Testimonial/Testimonial';
import Feature from './common/components/Feature/Feature';
import Article from './common/components/Article/Article';

function App() {

  const doctorsList = [
    {
        name: 'Dr. Ashley Cole',
        image: doctorImg1,
        speciality: 'Pediatrician',
        rating: 4.95,
        reviews: 123,
        recommend: true,
        description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum perspiciatis nihil cum amet atque dolorem aperiam nostrum officiis quia repellendus?',
        available: true,
    },
    {
        name: 'Dae yong jason Kang, MBBS, DD, FAGAD',
        image: doctorImg2,
        speciality: 'Pediatrician',
        rating: 4.2,
        reviews: 9,
        recommend: false,
        description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum perspiciatis nihil cum amet atque dolorem aperiam nostrum officiis quia repellendus?',
        available: true,
    },
    {
        name: 'Dr. Jerome Bell',
        image: doctorImg3,
        speciality: 'Pediatrician',
        rating: 4.6,
        reviews: 9,
        recommend: true,
        description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum perspiciatis nihil cum amet atque dolorem aperiam nostrum officiis quia repellendus?',
        available: true,
    },
  ];

  const specialityList = [
    {
      name: 'Familiy Medicine',
      icon: familyMedicine,
      description: 'From routine checkups to chronic condition management, find your trusted family doctor here.',
    },
    {
      name: 'Cardiology',
      icon: cardiology,
      description: `Listen to your heart's needs with the expertise of a trusted cardiologist, ensuring a healthy tomorrow`,
    },
    {
      name: 'Dermatology',
      icon: dermatology,
      description: 'Worried about rashes, acne, or aging skin? Find expert solutions for all your dermatological needs.',
    },
    {
      name: 'Pediatrics',
      icon: pediatrics,
      description: 'Growing healthy, growing happy: Expert care for your little ones, from newborns to teens.',
    },
  ];

  const testimonialList = [
    {
      name: 'Maria Jacob',
      speciality: 'Patient',
      image: userImg1,
      message: '"MyCarePedia has revolutionized the way I manage my patients. The appointment booking system is seamless, and the secure communication tools make it easy to stay connected with them. I also love the featured doctor section - it helps me attract new patients and showcase my expertise."'
    },
    {
      name: 'Abraham',
      speciality: 'Cardiologist',
      image: userImg1,
      message: '"MyCarePedia has revolutionized the way I manage my patients. The appointment booking system is seamless, and the secure communication tools make it easy to stay connected with them. I also love the featured doctor section - it helps me attract new patients and showcase my expertise."'
    },
    {
      name: 'Annie Rose',
      speciality: 'General Doctor',
      image: userImg1,
      message: '"MyCarePedia has revolutionized the way I manage my patients. The appointment booking system is seamless, and the secure communication tools make it easy to stay connected with them. I also love the featured doctor section - it helps me attract new patients and showcase my expertise."'
    },
  ];

  const featureList = [
    {
      icon: whyChooseIcon1,
      title: 'Comprehensive search<br/> and match',
      description: 'Go beyond the basics with advanced filters for specialty, language, insurance, doctor ratings, and even availability. Discover the perfect doctor for your specific needs.',
    },
    {
      icon: whyChooseIcon2,
      title: 'Seamless appointment<br/> management',
      description: 'No more juggling phone calls or emails. Schedule, reschedule, and confirm appointments online 24/7, with real-time integration to various practice management systems.',
    },
    {
      icon: whyChooseIcon3,
      title: 'Secure and centralized<br/> records',
      description: 'Protect your health information with HIPAA-compliant security. Store and access all your medical records, reports, images, and lab results in one convenient location.',
    },
    {
      icon: whyChooseIcon4,
      title: 'Family-friendly <br/>features',
      description: 'Easily manage medical records for dependents (children and parents) within your account, simplifying healthcare for the entire family.',
    },
    {
      icon: whyChooseIcon5,
      title: 'Empowering<br/> communication',
      description: 'Receive appointment reminders, health updates, and personalized educational resources. Connect with your doctor through secure messaging tools.',
    },
    {
      icon: whyChooseIcon6,
      title: 'Beyond appointments',
      description: 'MyCarePedia is more than just booking appointments. We offer a valuable platform for managing insurance information, validating coverage, and simplifying claims processing through LeadRCM integration.',
    },
    {
      icon: whyChooseIcon7,
      title: 'Community and trust',
      description: 'Build trust with doctor reviews and ratings. Access reliable health information through our curated library of resources.',
    },
    {
      icon: whyChooseIcon8,
      title: 'Focus on affordability',
      description: 'Choose from free and premium features to fit your needs and budget.',
    },
  ];

  const articleList = [
    {
      image: blog1,
      category: 'Orthopedics',
      date: '31 Jan 2024',
      title: `our Guide to Good Health: Exploring Today's Essential Healthcare`,
      blog: `At LeadRCM, we are a trusted provider of comprehensive revenue cycle At LeadRCM, we are a trusted provider of comprehensive revenue cycle`
    },
    {
      image: blog2,
      category: 'Orthopedics',
      date: '31 Jan 2024',
      title: `our Guide to Good Health: Exploring Today's Essential Healthcare`,
      blog: `At LeadRCM, we are a trusted provider of comprehensive revenue cycle At LeadRCM, we are a trusted provider of comprehensive revenue cycle`
    },
    {
      image: blog3,
      category: 'Orthopedics',
      date: '31 Jan 2024',
      title: `our Guide to Good Health: Exploring Today's Essential Healthcare`,
      blog: `At LeadRCM, we are a trusted provider of comprehensive revenue cycle At LeadRCM, we are a trusted provider of comprehensive revenue cycle`
    },
  ]

  return (
    <>
      {/* Top Nave */}
      <Header />

    {/* <!-- HEROSECTION --> */}
    <div className="herosection">
      <div className="container">
        <div className="row flex-lg-row flex-md-column-reverse flex-column-reverse">
          <div className="col-lg-6 col-md-12 left">
            <img src={bannerLogo} alt="" className="img-fluid" />
            <h1 className="banner-text">Your Health <span className="scndry-text">Simplified</span></h1>
            <p className="main-para">Find the right doctor, manage appointments, and control your records. All in one place.
            </p>
            <a href="" className="main-outline-btn">
              <p>Try MyCarePedia Now</p>
              <img src={btnArrow} alt="" />
            </a>
          </div>
          <div className="col-lg-6 col-md-12 right">
            <div className="hero-img-div">
              <img src={heroImg1} alt="" className="img-fluid img-1" />
              <img src={heroImg2} alt="" className="img-fluid img-2" />
              <img src={heroImg3} alt="" className="img-fluid img-3" />
            </div>
          </div>
        </div>
      </div>
    </div>






  

  

    {/* <!-- SPECIALITIES SECTION --> */}
    <div className="specialities-section">
      <div className="container">
        <div className="topsec">
          <h6 className="top-head text-center">Specialities</h6>
          <h2 className="main-head text-center">Find the Right Care For You</h2>
        </div>
        <div className="bottom-sec">
          {specialityList.map((row: any, index: any) => (
            <Speciality speciality={row} key={index}/>
          ))}
        </div>
        <div className="btn-sec">
          <a href="" className="main-fill-btn">
            <p>Explore Our Specialities</p>
            <img src={btnArrow} alt="" />
          </a>
        </div>
      </div>
    </div>




  


    {/* <!-- BENEFITS SECTION --> */}
    <div className="benefits-sec">
      <div className="container">
        <div className="topsec">
          <h6 className="top-head">Benefits</h6>
          <h2 className="main-head">How MyCarePedia<br />Makes Your Life Easier</h2>
        </div>
        <div className="bottom-sec">

          <div className="items">
            <div className="row">
              <div className="col-md-12 col-lg-6 img-sec">
                <img src={patientsImg} alt="" className="img-fluid" />
              </div>
              <div className="col-md-12 col-lg-6 details-sec ps-md-5 ps-0">
                <div className="tag-head">
                  <p>For Patients</p>
                </div>
                <div className="point-list">

                  <div className="points">
                    <img src={benefitsIcon} className="igm-div" alt="" />
                    <div className="desc">
                      <h6>Find the perfect doctor</h6>
                      <p className="main-para">Search by location, specialty, insurance, language, and ratings.</p>
                    </div>
                  </div>
                  <div className="points">
                    <img src={benefitsIcon} className="igm-div" alt="" />
                    <div className="desc">
                      <h6>Book appointments in seconds</h6>
                      <p className="main-para">Schedule online and manage them with ease.</p>
                    </div>
                  </div>
                  <div className="points">
                    <img src={benefitsIcon} className="igm-div" alt="" />
                    <div className="desc">
                      <h6>Manage your health records</h6>
                      <p className="main-para">View and share your records securely.</p>
                    </div>
                  </div>
                  <div className="points">
                    <img src={benefitsIcon} className="igm-div" alt="" />
                    <div className="desc">
                      <h6>Simplify insurance</h6>
                      <p className="main-para">Easily manage your insurance information and verification.</p>
                    </div>
                  </div>
                  <div className="points">
                    <img src={benefitsIcon} className="igm-div" alt="" />
                    <div className="desc">
                      <h6>Stay informed</h6>
                      <p className="main-para">Receive appointment reminders and health updates.</p>
                    </div>
                  </div>

                </div>
                <a href="" className="main-outline-btn">
                  <p>Try MyCarePedia Now</p>
                  <img src={btnArrow} alt="" />
                </a>
              </div>
            </div>
          </div>

          <div className="items">
            <div className="row flex-row-reverse">
              <div className="col-md-12 col-lg-6 img-sec">
                <img src={doctorsImg} alt="" className="img-fluid" />
              </div>
              <div className="col-md-12 col-lg-6 details-sec ps-0">
                <div className="tag-head">
                  <p>For Providers</p>
                </div>
                <div className="point-list">

                  <div className="points">
                    <img src={benefitsIcon} className="igm-div" alt="" />
                    <div className="desc">
                      <h6>Make your patient's life easy</h6>
                      <p className="main-para">Offer convenient online booking, appointment reminders, and secure
                        communication, fostering a positive patient experience.</p>
                    </div>
                  </div>
                  <div className="points">
                    <img src={benefitsIcon} className="igm-div" alt="" />
                    <div className="desc">
                      <h6>Effortlessly manage appointments</h6>
                      <p className="main-para">Schedule, reschedule, and confirm appointments online.</p>
                    </div>
                  </div>
                  <div className="points">
                    <img src={benefitsIcon} className="igm-div" alt="" />
                    <div className="desc">
                      <h6>Streamline insurance billing</h6>
                      <p className="main-para">Integrate with LeadRCM for hassle-free claims management.</p>
                    </div>
                  </div>
                  <div className="points">
                    <img src={benefitsIcon} className="igm-div" alt="" />
                    <div className="desc">
                      <h6>Build your online reputation</h6>
                      <p className="main-para">Receive and respond to patient reviews.</p>
                    </div>
                  </div>
                  <div className="points">
                    <img src={benefitsIcon} className="igm-div" alt="" />
                    <div className="desc">
                      <h6>Focus on what matters</h6>
                      <p className="main-para">Spend less time on administrative tasks and more time with patients.</p>
                    </div>
                  </div>

                </div>
                <a href="" className="main-outline-btn">
                  <p>Try MyCarePedia Now</p>
                  <img src={btnArrow} alt="" />
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>





  


    {/* <!-- CTA SECTION 1 --> */}
    <div className="first-cta-section">
      <div className="container">
        <div className="main-bg">
          <div className="row">
            <div className="col-12 col-md-5 col-lg-6 left">
              <img src={ctaDocImg} alt="" className="img-fluid" />
            </div>
            <div className="col-12 col-md-7 col-lg-6 right">
              <h2 className="main-head">Attract new patients and<br/> build your online reputation</h2>
              <a href="" className="main-fill-btn">
                <p>Try MyCarePedia Now</p>
                <img src={btnArrow} alt=""/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>




  




    {/* <!-- DOCTORS SECTION --> */}
    <div className="doctors-section">
      <div className="container">
        <div className="topsec">
          <h6 className="top-head">Featured Doctors</h6>
          <h2 className="main-head">Find Your Health <br className="d-block d-md-none" /> Match Today</h2>
          <p className="main-para">Find your ideal doctor effortlessly on MyCarePedia's Featured Doctors section for
            top-rated
            and new healthcare professionals.</p>
        </div>
        <div className="bottom-sec mt-5">
          <OwlCarousel className='doctors-items owl-theme'
            loop={true}
            nav={true}
            dots={false}
            autoplay={true}
            autoplayTimeout={5000}
            responsive={{
              0:{
                items:1
              },
              600:{
                items:2
              },
              1000:{
                items:3
              }
            }}
          >
            {doctorsList.map((row: any, index: any) => (
              <Doctors doctor={row} detailed={false} key={index}/>
            ))}
          </OwlCarousel>
          <a href="" className="main-outline-btn mt-3">
            <p>Explore More Doctors</p>
            <img src={btnArrow} alt="" />
          </a>
        </div>
      </div>
    </div>







  

    {/* <!-- TESTIMONALS --> */}
    <div className="tesimonial-section">
      <div className="container">
        <div className="topsec">
          <h6 className="top-head">Testimonials</h6>
          <h2 className="main-head">Hear What Others Are Saying</h2>
          <p className="main-para">At MyCarePedia, we're passionate about making healthcare easier and more accessible. But
            don't just take our word for it! Hear what our satisfied patients and providers have to say.</p>
        </div>

        <OwlCarousel className='testimonials mt-5 owl-theme'
          margin={10}
          loop={true}
          nav={true}
          dots={false}
          autoplay={true}
          autoplayTimeout={5000}
          responsive={{
              0:{
                items:1
              },
              600:{
                items:2
              },
              1000:{
                items:3
              }
          }}
        >
          {testimonialList.map((row: any, index: any) => (
            <Testimonial testimonial={row} key={index} />
          ))}
        </OwlCarousel>
      </div>
    </div>





    


    {/* <!-- WHYCHOOSE SECTION --> */}
    <div className="whychoose-section">
      <div className="container">
        <div className="topsec">
          <h6 className="top-head">What Makes Us Unique</h6>
          <h2 className="main-head">MyCarePedia: Your Family’s <br className="d-md-block d-lg-none" />Health Heaven</h2>
          <p className="main-para">MyCarePedia isn't just a doctor's portal; it's a comprehensive healthcare hub designed
            to simplify life for everyone in the family. Whether you're managing your child's well-being or navigating
            your own health journey, MyCarePedia offers unique features and benefits that make a real difference:
          </p>
        </div>
      </div>
      <div className="bottomsec">
        <div className="whychoose-items">
            {featureList.map((row: any, index: any) => (
              <Feature feature={row} key={index} />
            ))}
        </div>
      </div>
    </div>










    {/* <!-- SECOUND CTA SECTION --> */}
    <div className="scnd-cta-section">
      <div className="container">
        <div className="col-lg-6 col-12 left">
          <h2 className="main-head">Experience The<br/>MyCarePedia Difference!</h2>
          <p className="main-para">At MyCarePedia, we're passionate about making <br/>healthcare easier and more accessible.
          </p>
          <a href="" className="main-fill-btn">
            <p>SignUp For Free</p>
            <img src={btnArrow} alt="" />
          </a>
        </div>
      </div>
    </div>





    {/* <!-- UPADTES SECTION --> */}
    <div className="updates-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 left">
            <div className="img-div">
              <img src={updatesImg} alt="" className="img-fluid" />
            </div>
          </div>
          <div className="col-lg-6 col-md-12 right">
            <div className="topsec">
              <div className="top">
                <h6 className="top-head">Our Updates</h6>
                <h2 className="main-head">Lead RCM - Your Billing Partner</h2>
              </div>
              <p className="main-para">At LeadRCM, we are a trusted provider of comprehensive revenue cycle management
                services for Healthcare providers such as Doctors, Dentists, Therapists, Nursing Practitioners. With a
                deep understanding of the complex healthcare landscape, we are dedicated to optimizing your financial
                operations and maximizing your revenue potential. Our commitment to excellence shines through in our
                meticulous approach to billing, insurance follow-up, and ensure transparency by providing insightful
                analysis about the practice performance. </p>
              <a href="" className="main-fill-btn">
                <p>Discover Lead RCM</p>
                <img src={btnArrow} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>


    




    {/* <!-- BLOG SECTION --> */}
    <div className="blog-section">
      <div className="container">
        <div className="topsec">
          <h2 className="main-head text-center">Health Articles And Resources</h2>
        </div>
        {/* <div className="blogmain owl-carousel"> */}
        <OwlCarousel className='blogmain owl-theme'
            loop={true}
            nav={true}
            dots={false}
            autoplay={true}
            autoplayTimeout={5000}
            responsive={{
              0:{
                items:1
              },
              600:{
                items:2
              },
              1000:{
                items:3
              }
            }}
          >
          {articleList.map((row: any, index: any) => (
            <Article article={row} key={index} />
          ))}
        </OwlCarousel>

        <a href="" className="main-fill-btn mt-3">
          <p>More Blogs</p>
          <img src={btnArrow} alt="" />
        </a>
      </div>
    </div>


    


    {/* <!-- FOOTER SECTION --> */}
    <div className="footer-section">
      <div className="container">

        <div className="footer-cta">
          <div className="row">
            <div className="col-lg-6 col-md-7 col-12 left">
              <h2 className="main-head">Experience The Future <br className="d-lg-block d-md-none" />Of Healthcare</h2>
              <a href="" className="main-fill-btn">
                <p>SignUp For Now</p>
                <img src={btnArrow} alt="" />
              </a>
            </div>
            <div className="col-lg-6 col-md-5 col-12 right">
              <div className="img-div">
                <img src={footerCtaImg} alt="" className="img-fluid footer-cta-img" />
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="footer-main">
        <div className="container">

          <div className="row">

            <div className="col-lg-3 col-md-6 col-12">
              <div className="top-head">
                <h4 className="footer-head">Quick Links</h4>
                <button className="navbar-toggler d-md-none d-block" type="button" data-bs-toggle="collapse"
                  data-bs-target="#Quicklink" aria-controls="Quicklink" aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"><i className="fa-solid fa-arrow-right"></i></span>
                </button>
              </div>
              <div className="navbar navbar-expand-lg bg-body-tertiary mt-md-4 mt-3">
                <div className="collapse navbar-collapse justify-content-start" id="Quicklink">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a className="nav-link" aria-current="page" href="#">Home</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Patient Feedback</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Join a Televisit</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Browse</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">List Your Practice</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Resources</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12">
              <div className="top-head">
                <h4 className="footer-head">Insurance Carriers</h4>
                <button className="navbar-toggler d-md-none d-block" type="button" data-bs-toggle="collapse"
                  data-bs-target="#InsuranceCarriers" aria-controls="InsuranceCarriers" aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"><i className="fa-solid fa-arrow-right"></i></span>
                </button>
              </div>
              <div className="navbar navbar-expand-lg bg-body-tertiary mt-md-4 mt-3">
                <div className="collapse navbar-collapse justify-content-start" id="InsuranceCarriers">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a className="nav-link" aria-current="page" href="#">Aetna</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Aetna Dental</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Ambetter</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Blue Cross Blue Shield</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Blue Cross Blue Shield Dental</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Cigna</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Cigna Dental</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Delta Dental</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Florida Blue Cross Blue Shield</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-12">
              <div className="top-head">
                <h4 className="footer-head">Top Specialities</h4>
                <button className="navbar-toggler d-md-none d-block" type="button" data-bs-toggle="collapse"
                  data-bs-target="#TopSpecialities" aria-controls="TopSpecialities" aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"><i className="fa-solid fa-arrow-right"></i></span>
                </button>
              </div>
              <div className="navbar navbar-expand-lg bg-body-tertiary mt-md-4 mt-3">
                <div className="collapse navbar-collapse justify-content-start" id="TopSpecialities">
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <a className="nav-link" aria-current="page" href="#">Primary Care Doctor</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Urgent Care</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Dermatologist</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">OB-GYN</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Dentist</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Psychiatrist</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Ear, Nose & Throat Doctor</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Podiatrist</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Urologist</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Cardiologist</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Neurologist</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Orthopedic Surgeon</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Ophthalmologist</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">Pediatrician</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mt-md-5">
              <div className="footer-details">

                <div className="contact details-item">
                  <h4 className="footer-head">Contact Us</h4>
                  <div className="details mt-md-4 mt-3">
                    <a href="">
                      <i className="fa-solid fa-envelope-open"></i>
                      <p className="main-para">info@mycarepedia.com</p>
                    </a>
                    <a href="">
                      <i className="fa-solid fa-phone"></i>
                      <p className="main-para">987-456-132</p>
                    </a>
                  </div>
                </div>

                <div className="app-section details-item">
                  <h4 className="footer-head">Get Our App</h4>
                  <div className="details mt-md-4 mt-3">
                    <a href="">
                      <img src={appleStoreIcon} alt="" className="img-fluid" />
                    </a>
                    <a href="">
                      <img src={googlePlayIcon} alt="" className="img-fluid" />

                    </a>
                  </div>
                </div>

                <div className="app-section details-item">
                  <h4 className="footer-head">Follow Us On</h4>
                  <div className="details mt-md-4 mt-3 social-links">
                    <a href="">
                      <i className="fa-brands fa-facebook"></i>
                    </a>
                    <a href="">
                      <i className="fa-brands fa-x-twitter"></i>
                    </a>
                    <a href="">
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                    <a href="">
                      <i className="fa-brands fa-whatsapp"></i>
                    </a>
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>
      </div>

      <div className="bottom-footer">
        <div className="container">
          <div className="main row">
            <a href="#" className="logo-section col-md-6 col-12">
              <img src={footerLogo} alt="" className="img-fluid" />
            </a>
            <div className="newsletter-sec col-md-6 col-12">
              <div className="newsletter-div">
                <h4>Join Our Newsletter</h4>
                <div className="input-div">
                  <input type="mail" placeholder="Enter Email Address" />
                  <a href="" className="newsletter-submit">
                    <i className="fa-solid fa-paper-plane"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-sec">
        <div className="container">
          <img src={dividerSvg} alt="" className="img-fluid desktop" />
          <img src={footerDivideMobileSvg} alt="" className="img-fluid mobile" />
          <div className="copyright">
            <p className="main-para">© 2024 MyCarePedia. All Right Reserved.</p>
            <div className="sitemap-details">
              <a href="">Terms & Condition</a>
              <a href="">Sitemap</a>
              <a href="">Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </>
  );
}

export default App;
