import searchAd from '../../../assets/img/search-ad.jpg';
import tabletMain from '../../../assets/img/gif/tablet-main.gif';
import mobileMain from '../../../assets/img/gif/mobile-main.gif';
import SearchDoctors from '../../../assets/img/svg/search-doctors.svg';
import { Fragment, useEffect, useState } from 'react';
import './PatientRegistrationForm.css';
import { axiosInstance } from '../../interceptors/axiosConfig';
import axios from 'axios';

const PatientRegistrationForm = () => {
    const [step, setStep] = useState<number>(1);
    const [sendOtp, setSendOtp] = useState<Boolean>(false);
    const [otpVerified, setOtpVerified] = useState<Boolean>(false);
    const [formData, setFormData] = useState<any>({
        RegsteredUser: {
            UserType: "RegsteredUser",
            FirstName: "",
            MiddleName: "",
            LastName: "",
            DOB: "",
            Gender: "",
            Address1: "",
            Address2: "",
            City: "",
            State: "",
            Country: "",
            ZipCode: "",
            EmailId: "",
            MobilePhoneNumber: "",
            SecondaryNumber: "",
            PreferredCommunication: ""
        },
        UserId: "",
        Password: "",
        HaveInsurance: "",
        InsuranceType: "",
        RegsteredUserInsured: "No",
        RegiseredUsersDependents: [],
        Insured: {
            UserType: "Insured",
            FirstName: "",
            MiddleName: "",
            LastName: "",
            DOB: "",
            Gender: "",
            PersonalId: "",
            Address1: "",
            Address2: "",
            City: "",
            State: "",
            Country: "",
            EmailId: "",
            MobilePhoneNumber: "",
            SecondaryNumber: "",
            PreferredCommunication: "",
            PrivateInsuranceDetails: {
                InsuranceCarrierName: "",
                InsuranceCarrierId: "",
                InsurancePlan: "",
                InsuranceMemberID: "",
                InsuranceGroupID: ""
            },
            Medicaid: {
                MedicaidNumber: ""
            },
            Medicare: {
                MedicareNumber: ""
            },
            InsuranceCardUploaded: "",
            InsuredDriversLicenseNo: "",
            InsuredDriversLicenseState: "OK",
            InsuredDependents: []
        }
    });
    const [dependence, setDependence] = useState<any>([]);
    const [id, setId] = useState<string>('');
    const handleVerification = () => {
        
        setOtpVerified(true);
        setStep(step + 1);
    }

    const handleSubmitOtp = async () => {

        const url = '/generate/otp';
        const data = {
            EmailId: formData.RegsteredUser.EmailId,
            MobilePhoneNumber: formData.RegsteredUser.MobilePhoneNumber
        }

        try {
            const response = await axiosInstance({ data: {url:url, method:"post", data: data}, token: false });

            if(response?.data && response?.data?.status === 'success'){
                console.log(response?.data);
                
                alert(response?.data?.message);
                setSendOtp(true);
            }
        } catch(err){
            console.error(err);
        }
    }

    const handleRegsteredUserChange = (e: any) => {
        const { name, value } = e.target;
        const RegsteredUser = {...formData.RegsteredUser};
        RegsteredUser[name] = value;
        
        setFormData((prevData: any) => ({
            ...prevData,
            RegsteredUser: RegsteredUser
        }));
    }

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevData: any) => ({
            ...prevData,
            [name]: value
        }));
    }

    const handleInsuredUserChange = (e: any, type: any = '') => {
        const { name, value } = e.target;
        const InsuredUser = {...formData.Insured};
        if(type === 'Gender')
            InsuredUser.Gender = value;
        else if(type === 'Communication')
            InsuredUser.PreferredCommunication = value;
        else
            InsuredUser[name] = value;
        
        setFormData((prevData: any) => ({
            ...prevData,
            Insured: InsuredUser
        }));
    }

    const handlePrivateInsuranceDetailsChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevData: any) => ({
            ...prevData,
            Insured: {
                ...prevData.Insured,
                PrivateInsuranceDetails: {
                    ...prevData.Insured.PrivateInsuranceDetails,
                    [name]: value,
                }
            }
        }));
    }

    const handleMedicaidDetailsChange = (e: any) => {
        const { value } = e.target;
        setFormData((prevData: any) => ({
            ...prevData,
            Insured: {
                ...prevData.Insured,
                Medicaid: {
                    ...prevData.Insured.Medicaid,
                    MedicaidNumber: value,
                }
            }
        }));
    }

    const handleMedicareDetailsChange = (e: any) => {
        const { value } = e.target;
        setFormData((prevData: any) => ({
            ...prevData,
            Insured: {
                ...prevData.Insured,
                Medicare: {
                    ...prevData.Insured.Medicare,
                    MedicareNumber: value,
                }
            }
        }));
    }

    const addDependenceField = (e: any) => {
        e.preventDefault();

        const type = formData.RegsteredUserInsured === 'Yes' ? 'InsuredDependent' : 'RegsteredDependent';
        const dependenceList = {
            UserType: type,
            Relationship: "",
            FirstName: "",
            MiddleName: "",
            LastName: "",
            DOB: "",
            Gender: "",
            PersonalId: "",
            Address1: "",
            Address2: "",
            City: "",
            State: "",
            Country: "",
            ZipCode: "",
            EmailId: "",
            MobilePhoneNumber: "",
            SecondaryNumber: "",
            PreferredCommunication: ""	
        }

        setDependence([
            ...dependence,
            dependenceList
        ]);
    }

    const handleDependenceChange = (e: any, index: any, type: any = '') => {
        const { name, value } = e.target;
        const newDependence = [...dependence];
        if(type === 'Communication'){
            newDependence[index].PreferredCommunication = value;
        }
        else if(type === 'Gender'){
            newDependence[index].Gender = value;
        }
        else{
            newDependence[index][name] = value;
        }
        
        setDependence(newDependence);
    }

    const sameAddress = (e: any, index: any) => {
        const { value } = e.target;
        if(value === 'Yes'){
            let address = formData.Insured;
            if(formData.RegsteredUserInsured === 'No'){
                address = formData.RegsteredUser;
            }

            const newDependence = [...dependence];
            newDependence[index].Address1 = address.Address1;
            newDependence[index].Address2 = address.Address2;
            newDependence[index].City = address.City;
            newDependence[index].State = address.State;
            newDependence[index].Country = address.Country;
            newDependence[index].ZipCode = address.ZipCode;

            setDependence(newDependence);
        }
    }

    const handleFileChange = async (e: any) => {
        console.log('file', e.target.files[0]);
        console.log('name', e.target.name);

        const file = e.target.files[0];
        const name = e.target.name;
      
        try {
          const formData = new FormData();
          formData.append('file', file);
      
          const response = await axios.post('http://localhost:4000/file/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
      
          if (response.status === 200 && response?.data?.status === 'success') {
            console.log(response?.data?.fileUrl);
            setFormData((prevData: any) => ({
                ...prevData,
                Insured: {
                    ...prevData.Insured,
                    [name]: response?.data?.fileUrl,
                }
            }));
            
          } else {
            console.error('Failed to upload file on the server.');
          }
        } catch (error) {
          console.error('Error uploading file:', error);
        }
    };

    const handleSubmit = async () => {
        let newData: any = [];

        if(formData.RegsteredUserInsured === 'Yes'){
            newData = {
                ...formData,
                Insured: {
                    ...formData.Insured,
                    InsuredDependents: [...formData.Insured.InsuredDependents, ...dependence]
                }
            }
        }
        else {
            newData = {
                ...formData,
                RegiseredUsersDependents: [...formData.RegiseredUsersDependents, ...dependence]
            }
        }

        const url = id ? '/patient/update' : '/patient/register';
        const response = await axiosInstance({ data: {url:url, method:"post", data: newData}, token: false });

        if(response?.data && response?.data?.status === 'success'){
            alert(response?.data?.message);
        }
    }

    const getUserDetails = async () => {
        const url = `/patient/view?userId=${id}`
        const response = await axiosInstance({ data: {url:url, method:"get"}, token: false });
        const user = response?.data?.userDetails;
        console.log(response?.data);
        setFormData(user);
        if(user?.RegiseredUsersDependents.length) {
            setDependence(user?.RegiseredUsersDependents);
        }
        else {
            setDependence(user?.Insured?.InsuredDependents);
        }
        
        
    }

    useEffect(() => {
        if(id){
            getUserDetails();
        }
    }, []);
    

    return (
        <div className="reg-form-sec">
            <div className="form-main">
                <div className="row">
                    <div className="col-lg-9 col-12 left">
                        <div className="form-section">
                            <div className="topsec">
                                <h2 className="main-head">
                                    <img src={SearchDoctors} alt="" className="img-fluid" />
                                    {id ? 'Edit User Details' : 'Registration Form'}
                                </h2>
                            </div>
                            <form>
                                <div className="form-groups">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div className="input-div">
                                                <p className="label">Registering Email ID</p>
                                                {otpVerified || id ?
                                                    <input type="email" name="EmailId" value={formData.RegsteredUser.EmailId} onChange={(e: any) => handleRegsteredUserChange(e)} placeholder="example@mycarepedia.com" disabled />
                                                :
                                                    <input type="email" name="EmailId" value={formData.RegsteredUser.EmailId} onChange={(e: any) => handleRegsteredUserChange(e)} placeholder="example@mycarepedia.com" />
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div className="input-div">
                                                <p className="label">Registering Phone Number</p>
                                                {otpVerified || id ?
                                                    <input type="phone" name="MobilePhoneNumber" value={formData.RegsteredUser.MobilePhoneNumber} onChange={(e: any) => handleRegsteredUserChange(e)} placeholder="1-972-333-9282" disabled />
                                                :
                                                    <input type="phone" name="MobilePhoneNumber" value={formData.RegsteredUser.MobilePhoneNumber} onChange={(e: any) => handleRegsteredUserChange(e)} placeholder="1-972-333-9282" />
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div className="input-div">
                                                <p className="label">Secondary Phone Number</p>
                                                {otpVerified || id ?
                                                    <input type="phone" name="SecondaryNumber" value={formData.RegsteredUser.SecondaryNumber} onChange={(e: any) => handleRegsteredUserChange(e)} placeholder="1-972-333-9282" disabled />
                                                :
                                                    <input type="phone" name="SecondaryNumber" value={formData.RegsteredUser.SecondaryNumber} onChange={(e: any) => handleRegsteredUserChange(e)} placeholder="1-972-333-9282" />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-12 mt-lg-0 mt-md-3 mt-0">
                                            <div className="input-div">
                                                <p className="label">Preferred Communication</p>
                                                <div className="checkbox-div">
                                                    <div>
                                                        <p className="innerlabel">Email</p>
                                                        {otpVerified || id ?
                                                            <input type="radio" id="email-check" checked={formData.RegsteredUser.PreferredCommunication === 'email'} onChange={(e: any) => handleRegsteredUserChange(e)} name="PreferredCommunication" value="email" disabled />
                                                        :
                                                            <input type="radio" id="email-check" checked={formData.RegsteredUser.PreferredCommunication === 'email'} onChange={(e: any) => handleRegsteredUserChange(e)} name="PreferredCommunication" value="email" />
                                                        }
                                                    </div>
                                                    <div>
                                                        <p className="innerlabel">Phone</p>
                                                        {otpVerified || id ?
                                                            <input type="radio" id="phone-check" checked={formData.RegsteredUser.PreferredCommunication === 'phone'} onChange={(e: any) => handleRegsteredUserChange(e)} name="PreferredCommunication" value="phone" disabled />
                                                        :
                                                            <input type="radio" id="phone-check" checked={formData.RegsteredUser.PreferredCommunication === 'phone'} onChange={(e: any) => handleRegsteredUserChange(e)} name="PreferredCommunication" value="phone" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {sendOtp && !otpVerified &&
                                            <div className="col-lg-4 col-md-4 col-12">
                                                <div className="input-div">
                                                    <p className="label">OTP</p>
                                                    <input type="text" placeholder="Enter OTP" />
                                                </div>
                                            </div>
                                        }
                                        {(otpVerified || id) &&
                                            <>
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <div className="input-div">
                                                        <p className="label">Username</p>
                                                        <input type="text" onChange={(e: any) => handleChange(e)} name="UserId" value={formData.UserId} placeholder="Enter Username" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <div className="input-div">
                                                        <p className="label">Password</p>
                                                        <input type="password" onChange={(e: any) => handleChange(e)} name="Password" placeholder="Enter Password" />
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    {(!id && !sendOtp && !otpVerified) &&
                                        <div className="button">
                                            <button type="button" onClick={handleSubmitOtp}>SUBMIT</button>
                                        </div>
                                    }
                                    {(!id && sendOtp && !otpVerified) &&
                                        <div className="button">
                                            <button type="button" onClick={handleVerification}>VERIFY OTP</button>
                                        </div>
                                    }                                      
                                    <div className={`row ${(step > 1 || id) ? '' : 'd-none'}`}>
                                        <div className="col-lg-4 col-md-4 col-12">
                                            <div className="input-div">
                                                <p className="label">First Name</p>
                                                <input type="text" name="FirstName" value={formData.RegsteredUser.FirstName} onChange={(e: any) => handleRegsteredUserChange(e)} placeholder="Enter First Name" />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-12">
                                            <div className="input-div">
                                                <p className="label">Middle Name</p>
                                                <input type="text" name="MiddleName" value={formData.RegsteredUser.MiddleName} onChange={(e: any) => handleRegsteredUserChange(e)} placeholder="Enter Middle Name" />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-12">
                                            <div className="input-div">
                                                <p className="label">Last Name</p>
                                                <input type="text" name="LastName" value={formData.RegsteredUser.LastName} onChange={(e: any) => handleRegsteredUserChange(e)} placeholder="Enter Last Name" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`row ${(step > 1 || id) ? '' : 'd-none'}`}>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div className="input-div">
                                                <p className="label">Gender</p>
                                                <div className="radio-div">
                                                    <div>
                                                        <p className="innerlabel">Male</p>
                                                        <input type="radio" id="male" name="Gender" checked={formData.RegsteredUser.Gender === 'Male'} onChange={(e: any) => handleRegsteredUserChange(e)} value="Male" />
                                                    </div>
                                                    <div>
                                                        <p className="innerlabel">Female</p>
                                                        <input type="radio" id="female" name="Gender" checked={formData.RegsteredUser.Gender === 'Female'} onChange={(e: any) => handleRegsteredUserChange(e)} value="Female" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div className="input-div">
                                                <p className="label">D.O.B</p>
                                                <input type="date" id="dob" name="DOB" value={formData.RegsteredUser.DOB} onChange={(e: any) => handleRegsteredUserChange(e)} placeholder="dd-mm-yyyy" />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div className="input-div">
                                                <p className="label">Do You Have Insurance?</p>
                                                <div className="radio-div">
                                                    <div>
                                                        <p className="innerlabel">Yes</p>
                                                        <input type="radio" id="Insuranceyes" checked={formData.HaveInsurance === 'Yes'} onChange={(e: any) => handleChange(e)} name="HaveInsurance" value="Yes" />
                                                    </div>
                                                    <div>
                                                        <p className="innerlabel">No</p>
                                                        <input type="radio" id="Insuranceno" checked={formData.HaveInsurance === 'No'} onChange={(e: any) => handleChange(e)} name="HaveInsurance" value="No" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {formData.HaveInsurance === 'Yes' &&
                                        <div className={`row ${step > 1 ? '' : 'd-none'}`}>
                                            <div className="col-lg-4 col-md-6 col-12">
                                                <div className="input-div">
                                                    <p className="label">Are You Insured?</p>
                                                    <div className="radio-div">
                                                        <div>
                                                            <p className="innerlabel">Yes</p>
                                                            <input type="radio" id="insuredyes" checked={formData.RegsteredUserInsured === 'Yes'} onChange={(e: any) => handleChange(e)} name="RegsteredUserInsured" value="Yes" />
                                                        </div>
                                                        <div>
                                                            <p className="innerlabel">No</p>
                                                            <input type="radio" id="insuredno" checked={formData.RegsteredUserInsured === 'No'} onChange={(e: any) => handleChange(e)} name="RegsteredUserInsured" value="No" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className={`form-groups ${(step > 1 || id) ? '' : 'd-none'}`} id="RegisteringAddress">
                                    <div className="row">
                                        <h3 className="group-title">Registering Address</h3>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div className="input-div">
                                                <p className="label">Address Line 1</p>
                                                <input type="text" value={formData.RegsteredUser.Address1} onChange={(e: any) => handleRegsteredUserChange(e)} name="Address1" placeholder="House Number, Street Name" />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div className="input-div">
                                                <p className="label">Address Line 2</p>
                                                <input type="text" value={formData.RegsteredUser.Address2} onChange={(e: any) => handleRegsteredUserChange(e)} name="Address2" placeholder="House Number, Street Name" />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12">
                                            <div className="input-div">
                                                <p className="label">City</p>
                                                <input type="text" value={formData.RegsteredUser.City} onChange={(e: any) => handleRegsteredUserChange(e)} name="City" placeholder="Select City" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-12 mt-lg-0 mt-md-3 mt-0">
                                            <div className="input-div">
                                                <p className="label">State</p>
                                                <input type="text" value={formData.RegsteredUser.State} onChange={(e: any) => handleRegsteredUserChange(e)} name="State" placeholder="Select State" />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12 mt-lg-0 mt-md-3 mt-0">
                                            <div className="input-div">
                                                <p className="label">Country</p>
                                                <input type="text" value={formData.RegsteredUser.Country} onChange={(e: any) => handleRegsteredUserChange(e)} name="Country" placeholder="Select Country" />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-12 mt-lg-0 mt-md-3 mt-0">
                                            <div className="input-div">
                                                <p className="label">Zip Code</p>
                                                <input type="text" value={formData.RegsteredUser.ZipCode} onChange={(e: any) => handleRegsteredUserChange(e)} name="ZipCode" placeholder="Enter Zip Code" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {formData.HaveInsurance === 'Yes' &&
                                    <>
                                        {formData.RegsteredUserInsured === 'No' &&
                                            <div className={`form-groups ${(step > 1 || id) ? '' : 'd-none'}`} id="not-insured">
                                                <div className="row">
                                                    <h3 className="group-title">If you are not the Insured...</h3>
                                                    <div className="col-lg-3 col-md-6 col-12">
                                                        <div className="input-div">
                                                            <p className="label">Insured Email ID</p>
                                                            <input type="email" name="EmailId" value={formData.Insured.EmailId} onChange={(e: any) => handleInsuredUserChange(e)} placeholder="example@mycarepedia.com" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-12">
                                                        <div className="input-div">
                                                            <p className="label">Insured Phone Number</p>
                                                            <input type="phone" name="MobilePhoneNumber" value={formData.Insured.MobilePhoneNumber} onChange={(e: any) => handleInsuredUserChange(e)} placeholder="1-972-333-9282" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-12">
                                                        <div className="input-div">
                                                            <p className="label">Insured Phone Number</p>
                                                            <input type="phone" name="SecondaryNumber" value={formData.Insured.SecondaryNumber} onChange={(e: any) => handleInsuredUserChange(e)} placeholder="1-972-333-9282" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-md-12 col-12">
                                                        <div className="input-div">
                                                            <p className="label">Preferred Communication</p>
                                                            <div className="checkbox-div">
                                                                <div>
                                                                    <p className="innerlabel">Email</p>
                                                                    <input type="radio" id="email-check" checked={formData.Insured.PreferredCommunication === 'email'} onChange={(e: any) => handleInsuredUserChange(e, 'Communication')} name="InsuredPreferredCommunication" value="email" />
                                                                </div>
                                                                <div>
                                                                    <p className="innerlabel">Phone</p>
                                                                    <input type="radio" id="phone-check" checked={formData.Insured.PreferredCommunication === 'phone'} onChange={(e: any) => handleInsuredUserChange(e, 'Communication')} name="InsuredPreferredCommunication" value="phone" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-4 col-12">
                                                        <div className="input-div">
                                                            <p className="label">First Name</p>
                                                            <input type="text" onChange={(e: any) => handleInsuredUserChange(e)} name="FirstName" value={formData.Insured.FirstName} placeholder="Enter First Name" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-12">
                                                        <div className="input-div">
                                                            <p className="label">Middle Name</p>
                                                            <input type="text" onChange={(e: any) => handleInsuredUserChange(e)} name="MiddleName" value={formData.Insured.MiddleName} placeholder="Enter Middle Name" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-4 col-12">
                                                        <div className="input-div">
                                                            <p className="label">Last Name</p>
                                                            <input type="text" onChange={(e: any) => handleInsuredUserChange(e)} name="LastName" value={formData.Insured.LastName}  placeholder="Enter Last Name" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-6 col-12">
                                                        <div className="input-div">
                                                            <p className="label">Gender</p>
                                                            <div className="radio-div">
                                                                <div>
                                                                    <p className="innerlabel">Male</p>
                                                                    <input type="radio" id="not-insure-male" checked={formData.Insured.Gender === 'Male'} onChange={(e: any) => handleInsuredUserChange(e, 'Gender')} name="InsuredGender" value="Male" />
                                                                </div>
                                                                <div>
                                                                    <p className="innerlabel">Female</p>
                                                                    <input type="radio" id="not-insure-female" checked={formData.Insured.Gender === 'Female'} onChange={(e: any) => handleInsuredUserChange(e, 'Gender')} name="InsuredGender" value="Female" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-12">
                                                        <div className="input-div">
                                                            <p className="label">D.O.B</p>
                                                            <input type="date" id="insuredob" value={formData.Insured.DOB} onChange={(e: any) => handleInsuredUserChange(e)} name="DOB" placeholder="dd-mm-yyyy" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className={`form-groups ${(step > 1 || id) ? '' : 'd-none'}`} id="insurance-details">
                                            <div className="row">
                                                <h3 className="group-title">Insurance Details</h3>
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <div className="input-div">
                                                        <p className="label">Insurance Type</p>
                                                        <select id="insurance-carrier" name="InsuranceType" value={formData.InsuranceType} onChange={(e: any) => handleChange(e)}>
                                                            <option value="">-Select Option-</option>
                                                            <option value="Commercial Private Insurance">Commercial Private Insurance</option>
                                                            <option value="Medicaid">Medicaid</option>
                                                            <option value="Medicare">Medicare</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {formData.InsuranceType === 'Commercial Private Insurance' &&
                                                    <>
                                                        <div className="col-lg-4 col-md-4 col-12">
                                                            <div className="input-div">
                                                                <p className="label">Insurance Carrier Name</p>
                                                                <select id="insurance-carrier-name" value={formData.Insured.PrivateInsuranceDetails.InsuranceCarrierName} onChange={(e: any) => handlePrivateInsuranceDetailsChange(e)} name="InsuranceCarrierName">
                                                                    <option value="">-Select Option-</option>
                                                                    <option value="1">Blue cross Blue shield</option>
                                                                    <option value="2">Cigna</option>
                                                                    <option value="3">United Healthcare</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-4 col-12">
                                                            <div className="input-div">
                                                                <p className="label">Insurance Plan</p>
                                                                <select id="insurance-carrier-plan" value={formData.Insured.PrivateInsuranceDetails.InsurancePlan} onChange={(e: any) => handlePrivateInsuranceDetailsChange(e)} name="InsurancePlan">
                                                                    <option value="">-Selelct Insurance Plan-</option>
                                                                    <option value="1">PPO</option>
                                                                    <option value="2">HMO</option>
                                                                    <option value="3">Popular Plan</option>
                                                                    <option value="4">Other</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                {formData.InsuranceType === 'Medicare' && 
                                                    <div className="col-lg-4 col-md-6 col-12">
                                                        <div className="input-div">
                                                            <p className="label">Medicare</p>
                                                            <input type="text" value={formData.Insured.Medicare.MedicareNumber} onChange={(e: any) => handleMedicareDetailsChange(e)} name="Medicare" placeholder="Enter Medicare Number" />
                                                        </div>
                                                    </div>
                                                }
                                                {formData.InsuranceType === 'Medicaid' && 
                                                    <div className="col-lg-4 col-md-6 col-12">
                                                        <div className="input-div">
                                                            <p className="label">Medicaid</p>
                                                            <input type="text" value={formData.Insured.Medicaid.MedicaidNumber} onChange={(e: any) => handleMedicaidDetailsChange(e)} name="Medicaid" placeholder="Enter Medicaid Number" />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="row">
                                                {formData.InsuranceType === 'Commercial Private Insurance' &&
                                                    <>
                                                        <div className="col-lg-4 col-md-4 col-12">
                                                            <div className="input-div">
                                                                <p className="label">Insurance Member ID</p>
                                                                <input type="text" value={formData.Insured.PrivateInsuranceDetails.InsuranceMemberID} onChange={(e: any) => handlePrivateInsuranceDetailsChange(e)} name="InsuranceMemberID" placeholder="Enter Member ID" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4 col-md-6 col-12">
                                                            <div className="input-div">
                                                                <p className="label">Insurance Group ID</p>
                                                                <input type="text" value={formData.Insured.PrivateInsuranceDetails.InsuranceGroupID} onChange={(e: any) => handlePrivateInsuranceDetailsChange(e)} name="InsuranceGroupID" placeholder="Enter Insurance Group ID" />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                <div className="col-lg-4 col-md-6 col-12">
                                                    <div className="input-div">
                                                        <p className="label">Personal ID</p>
                                                        <input type="text" value={formData.Insured.PersonalId} onChange={(e: any) => handleInsuredUserChange(e)} name="PersonalId" placeholder="NNN-NN-NNNN" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 col-md-6 col-12">
                                                    <div className="input-div">
                                                        <p className="label">Insurance ID</p>
                                                        <div className="upload">
                                                            <label className="upload-resume">Upload File <i className="fa-solid fa-link"></i></label>
                                                            <input type="file" id="InsuranceCardUploaded" name="InsuranceCardUploaded" onChange={(e: any) => handleFileChange(e)} accept="application/pdf" style={{border: 'none', zIndex: 2, opacity: 0 }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-12 mt-lg-0 mt-md-3 mt-0">
                                                    <div className="input-div">
                                                        <p className="label">Insured ID/ State ID (or drivers license)</p>
                                                        <div className="upload">
                                                            <label className="upload-resume">Upload File <i className="fa-solid fa-link"></i></label>
                                                            <input type="file" id="InsuredDriversLicenseNo" name="InsuredDriversLicenseNo" onChange={(e: any) => handleFileChange(e)} accept="application/pdf" style={{border: 'none', zIndex: 2, opacity: 0 }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                        <div className={`form-groups ${step > 1 ? '' : 'd-none'}`} id="address">
                                            <div className="row">
                                                <h3 className="group-title">Insured Address</h3>
                                                <div className="col-lg-4 col-md-6 col-12">
                                                    <div className="input-div">
                                                        <p className="label">Address Line 1</p>
                                                        <input type="text" value={formData.Insured.Address1} onChange={(e: any) => handleInsuredUserChange(e)} name="Address1" placeholder="House Number, Street Name" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-12">
                                                    <div className="input-div">
                                                        <p className="label">Address Line 2</p>
                                                        <input type="text" value={formData.Insured.Address2} onChange={(e: any) => handleInsuredUserChange(e)} name="Address2" placeholder="House Number, Street Name" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-12">
                                                    <div className="input-div">
                                                        <p className="label">City</p>
                                                        <input type="text" value={formData.Insured.City} onChange={(e: any) => handleInsuredUserChange(e)} name="City" placeholder="Select City" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 col-md-6 col-12 mt-lg-0 mt-md-3 mt-0">
                                                    <div className="input-div">
                                                        <p className="label">State</p>
                                                        <input type="text" value={formData.Insured.State} onChange={(e: any) => handleInsuredUserChange(e)} name="State" placeholder="Enter Zip Code" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-12 mt-lg-0 mt-md-3 mt-0">
                                                    <div className="input-div">
                                                        <p className="label">Country</p>
                                                        <input type="text" value={formData.Insured.Country} onChange={(e: any) => handleInsuredUserChange(e)} name="Country" placeholder="Enter Zip Code" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-12 mt-lg-0 mt-md-3 mt-0">
                                                    <div className="input-div">
                                                        <p className="label">Zip Code</p>
                                                        <input type="text" value={formData.Insured.ZipCode} onChange={(e: any) => handleInsuredUserChange(e)} name="ZipCode" placeholder="Enter Zip Code" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className={`form-groups ${(step > 1 || id) ? '' : 'd-none'}`} id="dependencies">
                                    <div className='d-flex justify-content-between'>
                                        <h3 className="group-title">Add Dependence</h3>
                                        <a className="btn btn-primary d-inline-block" href="#" onClick={(e: any) => addDependenceField(e)}>+</a>
                                    </div>
                                    {dependence && dependence.map((row: any, index: any) => (
                                        
                                        <Fragment key={index}>
                                            <div className="row">
                                                <div className="col-lg-3 col-md-6 col-12">
                                                    <div className="input-div">
                                                        <p className="label">Email ID</p>
                                                        <input type="email" name="EmailId" value={row.EmailId} onChange={(e: any) => handleDependenceChange(e, index)} placeholder="example@mycarepedia.com" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-12">
                                                    <div className="input-div">
                                                        <p className="label">Phone Number</p>
                                                        <input type="phone" name="MobilePhoneNumber" value={row.MobilePhoneNumber} onChange={(e: any) => handleDependenceChange(e, index)} placeholder="1-972-333-9282" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-6 col-12">
                                                    <div className="input-div">
                                                        <p className="label">Secondary Phone Number</p>
                                                        <input type="phone" name="SecondaryNumber" value={row.SecondaryNumber} onChange={(e: any) => handleDependenceChange(e, index)} placeholder="1-972-333-9282" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-12 col-12">
                                                    <div className="input-div">
                                                        <p className="label">Preferred Communication</p>
                                                        <div className="checkbox-div">
                                                            <div>
                                                                <p className="innerlabel">Email</p>
                                                                <input type="radio" id="email-check" checked={row.PreferredCommunication === 'email'} onChange={(e: any) => handleDependenceChange(e, index, 'Communication')} name={`dependencePreferredCommunication${index}`} value="email" />
                                                            </div>
                                                            <div>
                                                                <p className="innerlabel">Phone</p>
                                                                <input type="radio" id="phone-check" checked={row.PreferredCommunication === 'phone'} onChange={(e: any) => handleDependenceChange(e, index, 'Communication')} name={`dependencePreferredCommunication${index}`} value="phone" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <div className="input-div">
                                                        <p className="label">First Name</p>
                                                        <input type="text" name="FirstName" value={row.FirstName} onChange={(e: any) => handleDependenceChange(e, index)} placeholder="Enter First Name" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <div className="input-div">
                                                        <p className="label">Middle Name</p>
                                                        <input type="text" name="MiddleName" value={row.MiddleName} onChange={(e: any) => handleDependenceChange(e, index)} placeholder="Enter Middle Name" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <div className="input-div">
                                                        <p className="label">Last Name</p>
                                                        <input type="text" name="LastName" value={row.LastName} onChange={(e: any) => handleDependenceChange(e, index)} placeholder="Enter Last Name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 col-md-6 col-12">
                                                    <div className="input-div">
                                                        <p className="label">Gender</p>
                                                        <div className="radio-div">
                                                            <div>
                                                                <p className="innerlabel">Male</p>
                                                                <input type="radio" id="not-insure-male" checked={row.Gender === 'Male'} onChange={(e: any) => handleDependenceChange(e, index, 'Gender')} name={`dependenceGender${index}`} value="Male" />
                                                            </div>
                                                            <div>
                                                                <p className="innerlabel">Female</p>
                                                                <input type="radio" id="not-insure-female" checked={row.Gender === 'Female'} onChange={(e: any) => handleDependenceChange(e, index, 'Gender')} name={`dependenceGender${index}`} value="Female" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-12">
                                                    <div className="input-div">
                                                        <p className="label">D.O.B</p>
                                                        <input type="date" id="dob" value={row.DOB} onChange={(e: any) => handleDependenceChange(e, index)} name="DOB" placeholder="dd-mm-yyyy" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <div className="input-div">
                                                        <p className="label">Relationship</p>
                                                        <select id="insurance-carrier" name="Relationship" value={row.Relationship} onChange={(e: any) => handleDependenceChange(e, index)}>
                                                            <option value="">-Select Option-</option>
                                                            <option value="Spouse">Spouse</option>
                                                            <option value="Son">Son</option>
                                                            <option value="Daughter">Daughter</option>
                                                            <option value="Father">Father</option>
                                                            <option value="Mother">Mother</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-12 col-12">
                                                    <div className="input-div">
                                                        <p className="label">Is the address the same as the {formData.RegsteredUserInsured === 'Yes' ? `Insured's` : `Registering` } address?</p>
                                                        <div className="radio-div">
                                                            <div>
                                                                <p className="innerlabel">Yes</p>
                                                                <input type="radio" id="same-address-yes" onChange={(e: any) => sameAddress(e, index)} name={`sameAddress${index}`} value="Yes" />
                                                            </div>
                                                            <div>
                                                                <p className="innerlabel">No</p>
                                                                <input type="radio" id="same-address-no" onChange={(e: any) => sameAddress(e, index)} name={`sameAddress${index}`} value="No" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <h3 className="group-title">Dependence Address</h3>
                                                <div className="col-lg-4 col-md-6 col-12">
                                                    <div className="input-div">
                                                        <p className="label">Address Line 1</p>
                                                        <input type="text" name="Address1" onChange={(e: any) => handleDependenceChange(e, index)} value={row.Address1} placeholder="House Number, Street Name" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-12">
                                                    <div className="input-div">
                                                        <p className="label">Address Line 2</p>
                                                        <input type="text" name="Address2" onChange={(e: any) => handleDependenceChange(e, index)} value={row.Address2} placeholder="House Number, Street Name" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-12">
                                                    <div className="input-div">
                                                        <p className="label">City</p>
                                                        <input type="text" name="City" onChange={(e: any) => handleDependenceChange(e, index)} value={row.City} placeholder="Select City" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4 col-md-6 col-12 mt-lg-0 mt-md-3 mt-0">
                                                    <div className="input-div">
                                                        <p className="label">State</p>
                                                        <input type="text" name="State" onChange={(e: any) => handleDependenceChange(e, index)} value={row.State} placeholder="Enter Zip Code" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-12 mt-lg-0 mt-md-3 mt-0">
                                                    <div className="input-div">
                                                        <p className="label">Country</p>
                                                        <input type="text" name="Country" onChange={(e: any) => handleDependenceChange(e, index)} value={row.Country} placeholder="Enter Zip Code" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-12 mt-lg-0 mt-md-3 mt-0">
                                                    <div className="input-div">
                                                        <p className="label">Zip Code</p>
                                                        <input type="text" name="ZipCode" onChange={(e: any) => handleDependenceChange(e, index)} value={row.ZipCode} placeholder="Enter Zip Code" />
                                                    </div>
                                                </div>
                                            </div>
                                            {index !== dependence.length - 1 ? <hr /> : '' }
                                        </Fragment>
                                    ))}
                                </div>
                                <div className={`button ${(step > 1 || id) ? '' : 'd-none'}`}>
                                    <button type="button" onClick={handleSubmit}>SUBMIT</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-3 col-12 right-ad">
                        <a href="">
                            <img src={searchAd} alt="" className="img-fluid d-lg-block d-md-none d-none" />
                            <img src={tabletMain} alt="" className="img-fluid d-lg-none d-md-block d-none" />
                            <img src={mobileMain} alt="" className="img-fluid d-lg-none d-md-none d-block" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PatientRegistrationForm;