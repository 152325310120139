import { useEffect, useState } from "react";
import logo from '../../../assets/img/logo/mycarepedia-logo.png';
import searchIcon from '../../../assets/img/svg/search-icon.svg';
import breadcrumbsImg from '../../../assets/img/svg/breadcrumbs.svg';
import searchDoctor1 from '../../../assets/img/search-doctor-1.png';
import searchDoctor2 from '../../../assets/img/search-doctor-2.png';
import searchDoctor3 from '../../../assets/img/search-doctor-3.png';
import axios from "axios";
import PatientRegistrationForm from "../PatientRegistrationForm/PatientRegistrationForm";
import SearchResult from "../SearchResult/SearchResult";

const Header = () => {
    const [loginPopup, setLoginPopup] = useState<Boolean>(false);
    const [searchPopup, setSearchPopup] = useState<Boolean>(false);
    const [patientForm, setPatientForm] = useState<Boolean>(false);
    const [myLocation, setMyLocation] = useState<any>([]);
    const [filteredResults, setFilteredResults] = useState<any>([]);

    const loginPopupTrigger = () => {
        setLoginPopup(!loginPopup);
    }

    const searchTrigger = (e: any) => {
        e.preventDefault();
        setSearchPopup(!searchPopup);
    }

    const patientRegistrationFormTrigger = () => {
        setPatientForm(!patientForm);
        loginPopupTrigger();
    }

    useEffect(() => {
        getLocation();
    }, []);

    const getLocation = async () => {
        let response = await axios.get('https://ipapi.co/json/');
        setMyLocation(response?.data);
    }

    const doctorsList = [
        {
            name: 'Dr. Ashley Cole',
            image: searchDoctor1,
            speciality: 'Pediatrician',
            rating: 4.95,
            reviews: 123,
            recommend: true,
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum perspiciatis nihil cum amet atque dolorem aperiam nostrum officiis quia repellendus?',
            available: true,
        },
        {
            name: 'Dae yong jason Kang, MBBS, DD, FAGAD',
            image: searchDoctor2,
            speciality: 'Pediatrician',
            rating: 4.2,
            reviews: 9,
            recommend: false,
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum perspiciatis nihil cum amet atque dolorem aperiam nostrum officiis quia repellendus?',
            available: true,
        },
        {
            name: 'Dr. Jerome Bell',
            image: searchDoctor3,
            speciality: 'Pediatrician',
            rating: 4.6,
            reviews: 9,
            recommend: true,
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum perspiciatis nihil cum amet atque dolorem aperiam nostrum officiis quia repellendus?',
            available: true,
        },
        {
            name: 'Dr. Courtney Henry',
            image: searchDoctor2,
            speciality: 'Pediatrician',
            rating: 4.1,
            reviews: 123,
            recommend: true,
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum perspiciatis nihil cum amet atque dolorem aperiam nostrum officiis quia repellendus?',
            available: true,
        },
        {
            name: 'Dr. Floyd Miles',
            image: searchDoctor3,
            speciality: 'Pediatrician',
            rating: 3.9,
            reviews: 9,
            recommend: true,
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum perspiciatis nihil cum amet atque dolorem aperiam nostrum officiis quia repellendus?',
            available: false,
        },
        {
            name: 'Dr. Devon Lane',
            image: searchDoctor1,
            speciality: 'Pediatrician',
            rating: 4.8,
            reviews: 9,
            recommend: true,
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum perspiciatis nihil cum amet atque dolorem aperiam nostrum officiis quia repellendus?',
            available: true,
        },
        {
            name: 'Dr. Ashley Cole',
            image: searchDoctor1,
            speciality: 'Pediatrician',
            rating: 4.95,
            reviews: 123,
            recommend: true,
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum perspiciatis nihil cum amet atque dolorem aperiam nostrum officiis quia repellendus?',
            available: true,
        },
        {
            name: 'Dae yong jason Kang, MBBS, DD, FAGAD',
            image: searchDoctor2,
            speciality: 'Pediatrician',
            rating: 4.2,
            reviews: 9,
            recommend: true,
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum perspiciatis nihil cum amet atque dolorem aperiam nostrum officiis quia repellendus?',
            available: false,
        },
        {
            name: 'Dr. Jerome Bell',
            image: searchDoctor3,
            speciality: 'Pediatrician',
            rating: 4.6,
            reviews: 9,
            recommend: true,
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum perspiciatis nihil cum amet atque dolorem aperiam nostrum officiis quia repellendus?',
            available: true,
        },
        {
            name: 'Dr. Courtney Henry',
            image: searchDoctor2,
            speciality: 'Pediatrician',
            rating: 4.1,
            reviews: 123,
            recommend: true,
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum perspiciatis nihil cum amet atque dolorem aperiam nostrum officiis quia repellendus?',
            available: true,
        },
        {
            name: 'Dr. Floyd Miles',
            image: searchDoctor3,
            speciality: 'Pediatrician',
            rating: 3.9,
            reviews: 9,
            recommend: true,
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum perspiciatis nihil cum amet atque dolorem aperiam nostrum officiis quia repellendus?',
            available: false,
        },
        {
            name: 'Dr. Devon Lane',
            image: searchDoctor1,
            speciality: 'Pediatrician',
            rating: 4.8,
            reviews: 9,
            recommend: true,
            description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Rerum perspiciatis nihil cum amet atque dolorem aperiam nostrum officiis quia repellendus?',
            available: true,
        },
    ];

    const doctorsChunks = () => {
        const result = [];
        const chunkSize = 6;

        for (let i = 0; i < doctorsList.length; i += chunkSize) {
            result.push(doctorsList.slice(i, i + chunkSize));
        }

        return result;
    }

    const doctorsSet = doctorsChunks();

    const specialitySearchList = [
        {"Category":"Speciality","Display_Name":"Allergy & Immunology Physician","Taxo_NPI_ID":"207K00000X"},
        {"Category":"Speciality","Display_Name":"Allergy Physician","Taxo_NPI_ID":"207KA0200X"},
        {"Category":"Speciality","Display_Name":"Clinical & Laboratory Immunology (Allergy & Immunology) Physician","Taxo_NPI_ID":"207KI0005X"},
        {"Category":"Speciality","Display_Name":"Anesthesiology Physician","Taxo_NPI_ID":"207L00000X"},
        {"Category":"Speciality","Display_Name":"Addiction Medicine (Anesthesiology) Physician","Taxo_NPI_ID":"207LA0401X"},
        {"Category":"Speciality","Display_Name":"Critical Care Medicine (Anesthesiology) Physician","Taxo_NPI_ID":"207LC0200X"},
        {"Category":"Speciality","Display_Name":"Hospice and Palliative Medicine (Anesthesiology) Physician","Taxo_NPI_ID":"207LH0002X"},
        {"Category":"Speciality","Display_Name":"Pain Medicine (Anesthesiology) Physician","Taxo_NPI_ID":"207LP2900X"},
        {"Category":"Speciality","Display_Name":"Pediatric Anesthesiology Physician","Taxo_NPI_ID":"207LP3000X"},
        {"Category":"Doctor","Display_Name":"PATRICIA LEON GUERRERO TAIMANGLOTAMUNING GU 96913 Clinical Psychologist","Taxo_NPI_ID":"1003000761"},
        {"Category":"Doctor","Display_Name":"ANNA C. MORGANOAKLAND CA 946115641 Specialist","Taxo_NPI_ID":"1003000225"},
        {"Category":"Doctor","Display_Name":"LESLIE A PATTERSONBEAVER FALLS PA 15010 Physical Therapy Assistant","Taxo_NPI_ID":"1003000241"},
        {"Category":"Doctor","Display_Name":"BEVERLY  BUDIGSACRAMENTO CA 958263889 Case Management Agency","Taxo_NPI_ID":"1003000266"},
        {"Category":"Doctor","Display_Name":"ROSIE K BLAKEMORENASHVILLE TN 372430001 Family Nurse Practitioner","Taxo_NPI_ID":"1003000282"},
        {"Category":"Doctor","Display_Name":"MARY COLLEEN RATHCLEVELAND OH 441950001 Registered Dietitian","Taxo_NPI_ID":"1003000324"},
        {"Category":"Doctor","Display_Name":"ESTHER Y THACHSAN FRANCISCO CA 941162856 Case Manager/Care Coordinator","Taxo_NPI_ID":"1003000332"},
        {"Category":"Doctor","Display_Name":"TODD  BLOWERSBAXTER MN 564258504 Speech-Language Pathologist","Taxo_NPI_ID":"1003000340"},
        {"Category":"Doctor","Display_Name":"LUTHER QUITORIANO BRAGANZALADY LAKE FL 321594347 Physical Therapist","Taxo_NPI_ID":"1003000381"},
        {"Category":"Doctor","Display_Name":"DAVID JAMES GIRARDIPITTSBURGH PA 152212134 Hospitalist Physician","Taxo_NPI_ID":"1003000407"},
        {"Category":"Doctor","Display_Name":"MARSHALL DEAN WEBSTERLAKE CITY FL 320251607 Chiropractor","Taxo_NPI_ID":"1003000415"},
        {"Category":"Doctor","Display_Name":"JENNIFER A VELOTTACLEVELAND OH 441061716 Obstetrics & Gynecology Physician","Taxo_NPI_ID":"1003000423"},
        {"Category":"Doctor","Display_Name":"MICHELLE SCHNABEL HORNERBALTIMORE MD 212241731 Psychiatry Physician","Taxo_NPI_ID":"1003000431"},
        {"Category":"Doctor","Display_Name":"JACLYN ANN EVANSANSONIA CT 064011964 Addiction (Substance Use Disorder) Counselor","Taxo_NPI_ID":"1003000449"},
        {"Category":"Doctor","Display_Name":"STACIE ANN HOBANREVERE MA 021513675 Speech-Language Pathologist","Taxo_NPI_ID":"1003000456"},
        {"Category":"Doctor","Display_Name":"LAUREL  WOLFKLAMATH FALLS OR 976035361 Physical Therapist","Taxo_NPI_ID":"1003000464"},
        {"Category":"Doctor","Display_Name":"EDGAR  FUENTESHOUSTON TX 770815537 Optician","Taxo_NPI_ID":"1003000472"},
        {"Category":"Doctor","Display_Name":"KEVIN BRADLEY ROTHCHILDAURORA CO 800452545 Surgery Physician","Taxo_NPI_ID":"1003000480"},
        {"Category":"Doctor","Display_Name":"KEISHA L. BUTLERDALLAS TX 752014312 Professional Counselor","Taxo_NPI_ID":"1003000506"},
        {"Category":"Doctor","Display_Name":"FREDERICK J WEIGANDDELTONA FL 327255876 Adult Medicine Physician","Taxo_NPI_ID":"1003000522"},
        {"Category":"Doctor","Display_Name":"AMANDA MARIE SEMONCHEQUAKERTOWN PA 18951 Internal Medicine Physician","Taxo_NPI_ID":"1003000530"}
    ];

    const handleSpecialityChange = (e: any) => {
        filterResults(e.target.value);
    };

    const filterResults = (query: String) => {
        const filtered = specialitySearchList.filter((item: any) =>
          item.Display_Name.toLowerCase().includes(query.toLowerCase())
        );
        setFilteredResults(filtered);
    };
    
    return (
        <>
            <div className="topnavbar">
                <div className="logo-main order-1 order-md-1 order-lg-1">
                    <a href="#">
                        <img src={logo} alt="" className="img-fluid" />
                    </a>
                </div>
                <div className="search-div order-3 order-md-3 order-lg-2 my-md-2">
                    <input type="text" placeholder="Speciality, Condition, Procedure, Doctors" onChange={(e: any) => handleSpecialityChange(e)} className="speciality" />
                    <input type="text" placeholder="City, State, Zip Code" className="zip-code" />
                    {/* <input type="text" placeholder="Health Insurance & Plan" className="insurance" /> */}
                    <a href="#" className="search-btn" onClick={(e) => searchTrigger(e)}>
                        <img src={searchIcon} alt="" />
                        <p className="d-md-block d-lg-none">Search</p>
                    </a>
                </div>
                <div className="login-div order-2 order-md-2 order-lg-3">
                    <a href="#" onClick={(e) => {loginPopupTrigger(); e.preventDefault();}} className="login-btn">Log In/Sign Up</a>
                    <div className={`login-dropdown ${!loginPopup ? 'd-none' : ''}`}>
                        <ul className="nav nav-pills mb-2" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-patient-tab" data-bs-toggle="pill" data-bs-target="#pills-patient" type="button" role="tab" aria-controls="pills-patient" aria-selected="true">Patient</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-doctors-tab" data-bs-toggle="pill" data-bs-target="#pills-doctors" type="button" role="tab" aria-controls="pills-doctors" aria-selected="false">Doctors</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-patient" role="tabpanel" aria-labelledby="pills-patient-tab">
                                <div className="login-signup col-12">
                                    <a href="" className="btn col-6">Log In</a>
                                    <a href="#" onClick={patientRegistrationFormTrigger} className="btn col-6">Sign Up</a>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-doctors" role="tabpanel" aria-labelledby="pills-doctors-tab">
                                <div className="login-signup col-6">
                                    <a href="" className="btn col-12">Log In</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!-- SUB NAVBAR --> */}
            <div className="sub-navbar">
                <nav className="navbar navbar-expand-lg bg-body-tertiary">
                    <div className="container-fluid">
                        <div className="auto-location order-1">
                            <i className="fa-solid fa-location-dot"></i>
                            <p>{myLocation.city} {myLocation.region}, {myLocation.country_name}</p>
                        </div>
                        <button className="navbar-toggler order-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                            <span className="navbar-toggler-icon">
                                <img src={breadcrumbsImg} alt="" className="img-fluid" />
                            </span>
                        </button>
                        <div className="offcanvas offcanvas-start d-lg-none d-md-block" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                            <div className="offcanvas-header">
                                <a href="#">
                                    <img src={logo} alt="" className="img-fluid" />
                                </a>
                                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="#">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Patient Feedback</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Join a Televisit</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Browse</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">List Your Practice</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Resources</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Contact</a>
                                </li>
                            </ul>
                        </div>
                        <div className="collapse navbar-collapse order-3 justify-content-end" id="navbarNavDropdown">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="#">Home</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Patient Feedback</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Join a Televisit</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Browse</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">List Your Practice</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Resources</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Contact</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>

            {/* <!-- SEARCH RESULTS --> */}
            {searchPopup &&
                <SearchResult doctorsSet={doctorsSet} />
            }

            {/* <!-- PATIENT FORM --> */}
            {patientForm && 
                <PatientRegistrationForm />
            }
        </>
    );
}

export default Header;